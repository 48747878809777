import { getRemoteConfig, getString } from 'firebase/remote-config';
import { defineStore } from 'pinia';

const emptyQuestion: Question = {
  id: '',
  correct: '',
  trivia: '',
  answers: [],
  takeTwo: [],
  dailywit: {
    html: '',
  },
  explanation: { html: '' },
  category: {
    name: '',
    locale: '',
    colorLight: { hex: '' },
    colorDark: { hex: '' },
    hoverText: '',
    correctResponseImmediate: '',
    correctResponseDashboard: '',
    wrongResponseImmediate: '',
    wrongResponseDashboard: '',
    id: '',
  },
  rank: {
    name: '',
    points: 0,
  },
  published: enumPublished.YES,
};

const emptyAnswer: Answer = {
  value: '',
  correct: '',
  score: '0',
};
// this store contains the game state for a the current question
export const useGameStore = defineStore(
  'game',
  () => {
    const currentQuestion = ref<Question>({ ...emptyQuestion });

    // this is an object where the key is the id of the question, and the values (value, correct, score) will be undefined if the user hasn't answered the question yet.
    // If it's not undefined, it means the user has answered the question: value is the answer they picked, correct is the correct answer, and score is the score they got.
    const currentAnswer = ref<Answer>({ ...emptyAnswer });
    const testingData = ref({
      current_date: '',
      enable_testing_mode: false,
    });

    const shouldShowCorrectAnswer = ref(false);
    const takeTwoUsed = ref(false);
    const addTime = ref(false);
    const retryGame = ref(false);
    const clearPowerUps = ref(true);
    const timeRemaining = ref(0);

    const setShouldShowCorrectAnswer = (value: boolean) => {
      shouldShowCorrectAnswer.value = value;
    };

    const setCurrentQuestion = (question: Question) => {
      currentQuestion.value = { ...question };
    };

    const resetGame = () => {
      currentAnswer.value = { ...emptyAnswer };
      takeTwoUsed.value = false;
      addTime.value = false;
      retryGame.value = false;
      resetTimeRemaining();
    };

    const setCurrentAnswer = (answer: Answer) => {
      currentAnswer.value = { ...answer };
    };

    const clearAnswer = () => {
      currentAnswer.value = { ...emptyAnswer };
    };

    const setTestingData = (data: { current_date: string; enable_testing_mode: boolean }) => {
      testingData.value = { ...data };
    };

    const useTakeTwo = () => {
      takeTwoUsed.value = true;
      resetTimeRemaining(15); // add 15 seconds
      clearAnswer();
    };

    const useAddTime = () => {
      addTime.value = true;
      resetTimeRemaining(15); // add 15 seconds
      clearAnswer();
    };
    const useRetryGame = () => {
      retryGame.value = true;
      clearAnswer();
      resetTimeRemaining();
    };

    const decrementTimeRemaining = () => {
      if (timeRemaining.value <= 0) {
        return;
      }
      timeRemaining.value--;
    };

    const resetTimeRemaining = (seconds?: number) => {
      const remoteConfig = getRemoteConfig();
      if (seconds) {
        timeRemaining.value = timeRemaining.value + seconds;
        return;
      }
      timeRemaining.value = parseInt(getString(remoteConfig, 'default_duration') ?? 30);
    };

    const getHasActivePowerUp = () => {
      return takeTwoUsed.value || addTime.value || retryGame.value;
    };

    return {
      currentQuestion,
      setCurrentQuestion,
      setCurrentAnswer,
      currentAnswer,
      clearAnswer,
      testingData,
      setTestingData,
      addTime,
      useAddTime,
      retryGame,
      useRetryGame,
      clearPowerUps,
      takeTwoUsed,
      useTakeTwo: useTakeTwo,
      shouldShowCorrectAnswer,
      setShouldShowCorrectAnswer,
      timeRemaining,
      decrementTimeRemaining,
      getHasActivePowerUp,
      resetGame,
    };
  },
  {
    persist: true,
  }
);
