<template>
  <ion-app>
    <ion-router-outlet />
    <CommonInAppPurchases />
    <Toast />
    <CommonBuyCoinsModal />
  </ion-app>
</template>
<script lang="ts" setup>
// add these to the head of the document
// <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
// <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
// <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
// <link rel="manifest" href="/site.webmanifest">

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import Toast from '~/components/Toast.vue';

// Note: trying to set meta tags with useHead does not work, they get overriden.  Partially by ionic.integrations.meta but also by something unknown.

if (Capacitor.isPluginAvailable('Keyboard') && Keyboard) {
  // Keyboard.setScroll({ isDisabled: true });
  Keyboard.setAccessoryBarVisible({ isVisible: true });
} else if (Capacitor.isNativePlatform()) {
  console.error('running native and keyboard plugin not found');
}
</script>
