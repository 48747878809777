import { default as complete_45profileJXkUpwLkX1Meta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/complete-profile.vue?macro=true";
import { default as create_45account9abHEaJFbgMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/create-account.vue?macro=true";
import { default as forgot_45passwordnRHLgiNYb9Meta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/forgot-password.vue?macro=true";
import { default as manage_45accountlYdBhqJwPtMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/manage-account.vue?macro=true";
import { default as save_45username6VcHXc7RmHMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/save-username.vue?macro=true";
import { default as sign_45in_45emailyaDKT86nHEMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-in-email.vue?macro=true";
import { default as sign_45in_45socialkBqVTaIhPCMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-in-social.vue?macro=true";
import { default as sign_45up_45userWLg3ek7m5MMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-up-user.vue?macro=true";
import { default as sign_45up1nAiCQ6bxQMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-up.vue?macro=true";
import { default as daily_45witUzzLsCVdRcMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/daily-wit.vue?macro=true";
import { default as correct_45answerh23AQycapiMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/correct-answer.vue?macro=true";
import { default as dashboardRMFO26LCMDMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/dashboard.vue?macro=true";
import { default as descriptioncHHFAS34UKMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/description.vue?macro=true";
import { default as leaderboardmZFdZVVyxpMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/leaderboard.vue?macro=true";
import { default as questionfZGFKJUyXXMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/question.vue?macro=true";
import { default as times_45upsr8LunLIlDMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/times-up.vue?macro=true";
import { default as uwitVqEQv5oXy2Meta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/uwit.vue?macro=true";
import { default as welcome_45backgUFmnB7W4pMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/welcome-back.vue?macro=true";
import { default as wrong_45answerLjSdaF5YN5Meta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/wrong-answer.vue?macro=true";
import { default as index15czssRytPMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/index.vue?macro=true";
import { default as page28pOTxEhqtfVMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/page28.vue?macro=true";
import { default as indexN7G6KeAm9CMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/sabbath/index.vue?macro=true";
import { default as user_45simulationAA1cTizz6vMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/user-simulation.vue?macro=true";
import { default as step2g1cg5Vqj5OMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step2.vue?macro=true";
import { default as step3U8QBdoQIutMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step3.vue?macro=true";
import { default as step4MYr3B9zxWpMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step4.vue?macro=true";
import { default as step5b4PRl6NAAlMeta } from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step5.vue?macro=true";
export default [
  {
    name: complete_45profileJXkUpwLkX1Meta?.name ?? "auth-complete-profile___en",
    path: complete_45profileJXkUpwLkX1Meta?.path ?? "/auth/complete-profile",
    meta: complete_45profileJXkUpwLkX1Meta || {},
    alias: complete_45profileJXkUpwLkX1Meta?.alias || [],
    redirect: complete_45profileJXkUpwLkX1Meta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: create_45account9abHEaJFbgMeta?.name ?? "auth-create-account___en",
    path: create_45account9abHEaJFbgMeta?.path ?? "/auth/create-account",
    meta: create_45account9abHEaJFbgMeta || {},
    alias: create_45account9abHEaJFbgMeta?.alias || [],
    redirect: create_45account9abHEaJFbgMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/create-account.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordnRHLgiNYb9Meta?.name ?? "auth-forgot-password___en",
    path: forgot_45passwordnRHLgiNYb9Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordnRHLgiNYb9Meta || {},
    alias: forgot_45passwordnRHLgiNYb9Meta?.alias || [],
    redirect: forgot_45passwordnRHLgiNYb9Meta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: manage_45accountlYdBhqJwPtMeta?.name ?? "auth-manage-account___en",
    path: manage_45accountlYdBhqJwPtMeta?.path ?? "/auth/manage-account",
    meta: manage_45accountlYdBhqJwPtMeta || {},
    alias: manage_45accountlYdBhqJwPtMeta?.alias || [],
    redirect: manage_45accountlYdBhqJwPtMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/manage-account.vue").then(m => m.default || m)
  },
  {
    name: save_45username6VcHXc7RmHMeta?.name ?? "auth-save-username___en",
    path: save_45username6VcHXc7RmHMeta?.path ?? "/auth/save-username",
    meta: save_45username6VcHXc7RmHMeta || {},
    alias: save_45username6VcHXc7RmHMeta?.alias || [],
    redirect: save_45username6VcHXc7RmHMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/save-username.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45emailyaDKT86nHEMeta?.name ?? "auth-sign-in-email___en",
    path: sign_45in_45emailyaDKT86nHEMeta?.path ?? "/auth/sign-in-email",
    meta: sign_45in_45emailyaDKT86nHEMeta || {},
    alias: sign_45in_45emailyaDKT86nHEMeta?.alias || [],
    redirect: sign_45in_45emailyaDKT86nHEMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-in-email.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45socialkBqVTaIhPCMeta?.name ?? "auth-sign-in-social___en",
    path: sign_45in_45socialkBqVTaIhPCMeta?.path ?? "/auth/sign-in-social",
    meta: sign_45in_45socialkBqVTaIhPCMeta || {},
    alias: sign_45in_45socialkBqVTaIhPCMeta?.alias || [],
    redirect: sign_45in_45socialkBqVTaIhPCMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-in-social.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45userWLg3ek7m5MMeta?.name ?? "auth-sign-up-user___en",
    path: sign_45up_45userWLg3ek7m5MMeta?.path ?? "/auth/sign-up-user",
    meta: sign_45up_45userWLg3ek7m5MMeta || {},
    alias: sign_45up_45userWLg3ek7m5MMeta?.alias || [],
    redirect: sign_45up_45userWLg3ek7m5MMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-up-user.vue").then(m => m.default || m)
  },
  {
    name: sign_45up1nAiCQ6bxQMeta?.name ?? "auth-sign-up___en",
    path: sign_45up1nAiCQ6bxQMeta?.path ?? "/auth/sign-up",
    meta: sign_45up1nAiCQ6bxQMeta || {},
    alias: sign_45up1nAiCQ6bxQMeta?.alias || [],
    redirect: sign_45up1nAiCQ6bxQMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: daily_45witUzzLsCVdRcMeta?.name ?? "daily-wit___en",
    path: daily_45witUzzLsCVdRcMeta?.path ?? "/daily-wit",
    meta: daily_45witUzzLsCVdRcMeta || {},
    alias: daily_45witUzzLsCVdRcMeta?.alias || [],
    redirect: daily_45witUzzLsCVdRcMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/daily-wit.vue").then(m => m.default || m)
  },
  {
    name: correct_45answerh23AQycapiMeta?.name ?? "game-correct-answer___en",
    path: correct_45answerh23AQycapiMeta?.path ?? "/game/correct-answer",
    meta: correct_45answerh23AQycapiMeta || {},
    alias: correct_45answerh23AQycapiMeta?.alias || [],
    redirect: correct_45answerh23AQycapiMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/correct-answer.vue").then(m => m.default || m)
  },
  {
    name: dashboardRMFO26LCMDMeta?.name ?? "game-dashboard___en",
    path: dashboardRMFO26LCMDMeta?.path ?? "/game/dashboard",
    meta: dashboardRMFO26LCMDMeta || {},
    alias: dashboardRMFO26LCMDMeta?.alias || [],
    redirect: dashboardRMFO26LCMDMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/dashboard.vue").then(m => m.default || m)
  },
  {
    name: descriptioncHHFAS34UKMeta?.name ?? "game-description___en",
    path: descriptioncHHFAS34UKMeta?.path ?? "/game/description",
    meta: descriptioncHHFAS34UKMeta || {},
    alias: descriptioncHHFAS34UKMeta?.alias || [],
    redirect: descriptioncHHFAS34UKMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/description.vue").then(m => m.default || m)
  },
  {
    name: leaderboardmZFdZVVyxpMeta?.name ?? "game-leaderboard___en",
    path: leaderboardmZFdZVVyxpMeta?.path ?? "/game/leaderboard",
    meta: leaderboardmZFdZVVyxpMeta || {},
    alias: leaderboardmZFdZVVyxpMeta?.alias || [],
    redirect: leaderboardmZFdZVVyxpMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: questionfZGFKJUyXXMeta?.name ?? "game-question___en",
    path: questionfZGFKJUyXXMeta?.path ?? "/game/question",
    meta: questionfZGFKJUyXXMeta || {},
    alias: questionfZGFKJUyXXMeta?.alias || [],
    redirect: questionfZGFKJUyXXMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/question.vue").then(m => m.default || m)
  },
  {
    name: times_45upsr8LunLIlDMeta?.name ?? "game-times-up___en",
    path: times_45upsr8LunLIlDMeta?.path ?? "/game/times-up",
    meta: times_45upsr8LunLIlDMeta || {},
    alias: times_45upsr8LunLIlDMeta?.alias || [],
    redirect: times_45upsr8LunLIlDMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/times-up.vue").then(m => m.default || m)
  },
  {
    name: uwitVqEQv5oXy2Meta?.name ?? "game-uwit___en",
    path: uwitVqEQv5oXy2Meta?.path ?? "/game/uwit",
    meta: uwitVqEQv5oXy2Meta || {},
    alias: uwitVqEQv5oXy2Meta?.alias || [],
    redirect: uwitVqEQv5oXy2Meta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/uwit.vue").then(m => m.default || m)
  },
  {
    name: welcome_45backgUFmnB7W4pMeta?.name ?? "game-welcome-back___en",
    path: welcome_45backgUFmnB7W4pMeta?.path ?? "/game/welcome-back",
    meta: welcome_45backgUFmnB7W4pMeta || {},
    alias: welcome_45backgUFmnB7W4pMeta?.alias || [],
    redirect: welcome_45backgUFmnB7W4pMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/welcome-back.vue").then(m => m.default || m)
  },
  {
    name: wrong_45answerLjSdaF5YN5Meta?.name ?? "game-wrong-answer___en",
    path: wrong_45answerLjSdaF5YN5Meta?.path ?? "/game/wrong-answer",
    meta: wrong_45answerLjSdaF5YN5Meta || {},
    alias: wrong_45answerLjSdaF5YN5Meta?.alias || [],
    redirect: wrong_45answerLjSdaF5YN5Meta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/game/wrong-answer.vue").then(m => m.default || m)
  },
  {
    name: index15czssRytPMeta?.name ?? "index___en",
    path: index15czssRytPMeta?.path ?? "/",
    meta: index15czssRytPMeta || {},
    alias: index15czssRytPMeta?.alias || [],
    redirect: index15czssRytPMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: page28pOTxEhqtfVMeta?.name ?? "page28___en",
    path: page28pOTxEhqtfVMeta?.path ?? "/page28",
    meta: page28pOTxEhqtfVMeta || {},
    alias: page28pOTxEhqtfVMeta?.alias || [],
    redirect: page28pOTxEhqtfVMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/page28.vue").then(m => m.default || m)
  },
  {
    name: indexN7G6KeAm9CMeta?.name ?? "sabbath___en",
    path: indexN7G6KeAm9CMeta?.path ?? "/sabbath",
    meta: indexN7G6KeAm9CMeta || {},
    alias: indexN7G6KeAm9CMeta?.alias || [],
    redirect: indexN7G6KeAm9CMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/sabbath/index.vue").then(m => m.default || m)
  },
  {
    name: user_45simulationAA1cTizz6vMeta?.name ?? "user-simulation___en",
    path: user_45simulationAA1cTizz6vMeta?.path ?? "/user-simulation",
    meta: user_45simulationAA1cTizz6vMeta || {},
    alias: user_45simulationAA1cTizz6vMeta?.alias || [],
    redirect: user_45simulationAA1cTizz6vMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/user-simulation.vue").then(m => m.default || m)
  },
  {
    name: step2g1cg5Vqj5OMeta?.name ?? "welcome-step2___en",
    path: step2g1cg5Vqj5OMeta?.path ?? "/welcome/step2",
    meta: step2g1cg5Vqj5OMeta || {},
    alias: step2g1cg5Vqj5OMeta?.alias || [],
    redirect: step2g1cg5Vqj5OMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step2.vue").then(m => m.default || m)
  },
  {
    name: step3U8QBdoQIutMeta?.name ?? "welcome-step3___en",
    path: step3U8QBdoQIutMeta?.path ?? "/welcome/step3",
    meta: step3U8QBdoQIutMeta || {},
    alias: step3U8QBdoQIutMeta?.alias || [],
    redirect: step3U8QBdoQIutMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step3.vue").then(m => m.default || m)
  },
  {
    name: step4MYr3B9zxWpMeta?.name ?? "welcome-step4___en",
    path: step4MYr3B9zxWpMeta?.path ?? "/welcome/step4",
    meta: step4MYr3B9zxWpMeta || {},
    alias: step4MYr3B9zxWpMeta?.alias || [],
    redirect: step4MYr3B9zxWpMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step4.vue").then(m => m.default || m)
  },
  {
    name: step5b4PRl6NAAlMeta?.name ?? "welcome-step5___en",
    path: step5b4PRl6NAAlMeta?.path ?? "/welcome/step5",
    meta: step5b4PRl6NAAlMeta || {},
    alias: step5b4PRl6NAAlMeta?.alias || [],
    redirect: step5b4PRl6NAAlMeta?.redirect,
    component: () => import("/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/pages/welcome/step5.vue").then(m => m.default || m)
  }
]