import { defineStore } from 'pinia';
import { Product } from './Types';

export const useProductStore = defineStore('product', () => {
  const isNativeLoading = ref(false);
  const showBuyCoinsModal = ref(false);
  const currentProducts = ref<Product[]>([]);
  const setProducts = (products: Product[]) => {
    currentProducts.value = products;
  };

  const toggleBuyCoinsModal = (shouldShow?: boolean) => {
    if (shouldShow !== undefined) {
      showBuyCoinsModal.value = shouldShow;
    } else {
      showBuyCoinsModal.value = !showBuyCoinsModal.value;
    }
  };
  return {
    currentProducts,
    setProducts,
    toggleBuyCoinsModal,
    showBuyCoinsModal,
    isNativeLoading,
  };
});
