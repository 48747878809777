import * as Sentry from '@sentry/vue';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  let appVersion = config.public.appVersion;

  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((info) => {
      console.log('build', info.build);
      appVersion = info.version;
    });
  }

  console.log('appVersion: ', appVersion);
  Sentry.init({
    release: `${config.public.appName}@${appVersion}`,
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.environment,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
      new Sentry.Replay(),
    ],
    enabled: config.public.sentryEnabled,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
