import { enumPublished } from '../../utils/Types';
export enum LedgerTransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}
export enum TransactionVendorEnum {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  STRIPE = 'STRIPE',
}
export interface Product {
  id: string;
  name: string;
  coins: number;
  bonusPoints: number;
  price: number;
  published: enumPublished;
  appStoreProductId: string;
  icon: string;
}
