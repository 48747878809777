<!-- The InAppPurchases component fetches the products from firestore and if native, initializes in app purchases -->
<template>
  <div></div>
</template>

<script setup lang="ts">
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
import type { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { useQuery } from '@tanstack/vue-query';
import { Capacitor } from '@capacitor/core';

// Order of events:
// get products from firestore
// register products in store
// wait for store.ready to fire
// add listeners to products

const productStore = useProductStore();

// check if device is native (not running in web browser)
const isNativePlatform = ref(false);
isNativePlatform.value = Capacitor.isNativePlatform();

console.log('isNativePlatform: ' + isNativePlatform.value);

// if (isNativePlatform.value === true) {
//   // this setting makes the plugin log as much data as possible to the console
//   InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
// }

// This will register products in the app store
const registerProducts = (products: Product[]) => {
  console.info('registerProducts called');
  products.forEach((product) => {
    InAppPurchase2.register({ id: product.appStoreProductId, type: InAppPurchase2.CONSUMABLE, alias: product.name });
  });
  InAppPurchase2.refresh();
  console.info('registerProducts finished');
};

const { mutate, isLoading: loadingNewTransaction } = addCoinsMutation();

const addCoins = (id: string, product: IAPProduct) => {
  productStore.toggleBuyCoinsModal(false);
  if (!product.transaction) {
    console.error('No transaction found');
    return;
  }

  // get the vendor from Capacitor
  const platform = Capacitor.getPlatform();
  const vendor = platform === 'ios' ? TransactionVendorEnum.APPLE : TransactionVendorEnum.GOOGLE;

  mutate(
    {
      productId: id,
      meta: product,
      vendor,
      vendorId: product.transaction.id,
    },
    {
      onSuccess: (data) => {
        console.info('addCoins finished', data);
        productStore.isNativeLoading = false;
      },
    }
  );
};

// This will add event listeners to define actions to take when a product is approved, updated, verfied, etc.
const addPurchaseListeners = (products: Product[]) => {
  products.forEach((product) => {
    if (!InAppPurchase2.get(product.appStoreProductId).canPurchase) {
      console.error('Cannot purchase');
      return;
    }
    InAppPurchase2.when(product.appStoreProductId)
      .approved((p: IAPProduct) => {
        console.log('approved transaction', p);
        p.verify();
      })
      .initiated(() => {
        productStore.isNativeLoading = true;
      })
      .verified((p: IAPProduct) => {
        console.log('verified transaction', p);
        addCoins(product.id, p);
        p.finish();
      })
      .cancelled((p: IAPProduct) => {
        productStore.isNativeLoading = false;
      });
  });
};

// This will initialize the store
const initStore = () => {
  if (isNativePlatform.value === true && firestoreProductsData.value) {
    addPurchaseListeners(firestoreProductsData.value);
  }
};

// Get the products from firestore
const { data: firestoreProductsData, isLoading: loadingProducts } = useQuery(['products'], () => productQuery(), {
  ...globalVueQueryOptions(),
  select: (firestoreProductsRaw) => {
    // Filter out unpublished products
    const dataFiltered = firestoreProductsRaw.filter((product) => product.published === enumPublished.YES);
    // Sort products by price
    const dataSorted = dataFiltered.sort(function (a, b) {
      return a.price - b.price;
    });
    // Replace product icon URLs with local image paths
    const dataWithLocalImages = dataSorted.map((product) => {
      return {
        ...product,
        icon: `/images/${product.icon}`,
      };
    });
    // if device is native, register products in app store
    if (isNativePlatform.value === true) {
      registerProducts(dataWithLocalImages);
    }
    return dataWithLocalImages;
  },
});

// when the store is ready, init the store (which adds listeners to products)
const storeReady = ref(false);
InAppPurchase2.ready(() => {
  storeReady.value = true;
  console.info('in app purchase store ready: ' + storeReady.value);
  initStore();
});
</script>
