<template>
  <div v-if="productStore.isNativeLoading === true" class="flex justify-center">
    <div class="flex justify-center items-center">
      <Icon name="ion:refresh-outline" class="animate-spin h-10 w-10 text-white" />
    </div>
  </div>
  <div v-if="productStore.isNativeLoading === false">
    <div class="flex flex-wrap">
      <div v-for="(product, index) in products" :key="product.id" class="flex w-1/2 items-end justify-center">
        <div class="flex flex-col justify-end items-center py-2">
          <div>
            <img :src="product.icon" class="w-24 py-1" />
          </div>
          <div class="text-white py-1 text-center">
            <div class="font-bold" :class="products && index > 1 ? 'text-xl' : 'text-lg'">{{ product.coins }} coins</div>
            <div v-if="product.bonusPoints" class="font-quicksand text-sm font-medium">
              + {{ formatNumber(product.bonusPoints) }} point bonus!
            </div>
          </div>
          <div class="py-1">
            <button
              class="min-w-[90px] font-quicksand font-medium text-black bg-white py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#7800CD] active:text-white"
              @click="purchase(product.appStoreProductId)"
            >
              {{ formatCurrency(product.price) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';

const productStore = useProductStore();

// props
interface Props {
  products: Product[] | undefined;
}
defineProps<Props>();

// this initiates the purchase process, which is then handled in InAppPurchases.vue
const purchase = async (productId: string) => {
  try {
    await InAppPurchase2.order(productId);
    console.log('purchase started');
  } catch (error) {
    console.error('error starting in app purchase, error: ' + error);
  }
};
</script>
