// modified to only authenticate the web SDK.  more info: https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md

import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithCredential,
  signOut,
  signInAnonymously,
  connectAuthEmulator,
  linkWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';

// const applyActionCode = async () => {
//   await FirebaseAuthentication.applyActionCode({ oobCode: '1234' });
// };

// use web sdk directy rather than this
// export const createUserWithEmailAndPassword = async () => {
//   const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
//     email: 'mail@exmaple.com',
//     password: '1234',
//   });
//   return result.user;
// };

export const confirmPasswordReset = async () => {
  await FirebaseAuthentication.confirmPasswordReset({
    oobCode: '1234',
    newPassword: '4321',
  });
};

export const getCurrentUser = async () => {
  // const userStore = useUserStore();
  const webUser = await getAuth().currentUser;
  return webUser;
  // return userStore.userInfo;
};

export const getIdToken = async () => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    console.info('no current user');
    return;
  }
  // const webToken = await getAuth().currentUser?.getIdToken();
  const webToken = await currentUser.getIdToken();
  return webToken;
};

export const sendEmailVerification = async () => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.sendEmailVerification();
};

export const sendPasswordResetEmail = async (email: string) => {
  await FirebaseAuthentication.sendPasswordResetEmail({
    email,
  });
};

export const linkWithApple = async () => {
  if (Capacitor.isNativePlatform() === false) {
    return await FirebaseAuthentication.linkWithApple({ mode: 'redirect' });
  }
  const guestUser = await getAuth().currentUser; // run this before signing in with apple so that it is the guest user
  const result = await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true, mode: 'redirect' });
  // 2. Sign in on the web layer using the id token and nonce
  const provider = new OAuthProvider('apple.com');
  const credential = provider.credential({
    idToken: result.credential?.idToken,
    rawNonce: result.credential?.nonce,
  });
  if (!guestUser) {
    console.error('no current user');
    return;
  }
  return await linkWithCredential(guestUser, credential);
};

export const linkWithGoogle = async () => {
  if (Capacitor.isNativePlatform() === false) {
    return await FirebaseAuthentication.linkWithGoogle({ mode: 'redirect' });
  }
  const guestUser = await getAuth().currentUser; // run this before signing in with apple so that it is the guest user
  const result = await FirebaseAuthentication.signInWithGoogle({ mode: 'redirect' });
  // 2. Sign in on the web layer using the id token
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  if (!guestUser) {
    console.error('no current user');
    return;
  }
  return await linkWithCredential(guestUser, credential);
};

// export const linkCurrentUserWithCredential = (credential: AuthCredential): Promise<UserCredential> => {
//   const auth = getAuth();
//   if (!auth.currentUser) {
//     throw new Error('No current user');
//   }
//   return linkWithCredential(auth.currentUser, credential);
// };

// TODO: This is not working
// checkout https://github.com/capawesome-team/capacitor-firebase/blob/7e71fee7f6387147d5cc48f5af4a3df4d554d510/packages/authentication/src/web.ts#L621
// export const linkWithCredentialCap = async (email: string, password: string) => {
//   const currentUser = await getCurrentUser();
//   if (!currentUser) {
//     return;
//   }
//   await FirebaseAuthentication.linkWithEmailAndPassword({ email, password });
// };

// const setLanguageCode = async () => {
//   await FirebaseAuthentication.setLanguageCode({ languageCode: 'en-US' });
// };

export const signInAnonymouslyWeb = async () => {
  const result = await signInAnonymously(getAuth());
  return result.user;
};

// signInAnonymously cannot be used in combination with skipNativeAuth
// export const signInAnonymouslyNative = async () => {
//   const result = await FirebaseAuthentication.signInAnonymously();
//   return result.user;
// }

export const signInWithApple = async () => {
  const result = await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true, mode: 'redirect' });
  // 2. Sign in on the web layer using the id token and nonce
  const provider = new OAuthProvider('apple.com');
  const credential = provider.credential({
    idToken: result.credential?.idToken,
    rawNonce: result.credential?.nonce,
  });
  const auth = getAuth();
  const userCredential = await signInWithCredential(auth, credential);
  return { credential, userCredential };
};

export const signInWithEmailAndPasswordJs = async (email: string, password: string) => {
  const result = await signInWithEmailAndPassword(getAuth(), email, password);
  return result.user;
};

// const signInWithEmailLink = async () => {
//   // Get the email if available. This should be available if the user completes
//   // the flow on the same device where they started it.
//   const emailLink = window.location.href;
//   // Confirm the link is a sign-in with email link.
//   const { isSignInWithEmailLink } =
//     await FirebaseAuthentication.isSignInWithEmailLink({
//       emailLink,
//     });
//   if (!isSignInWithEmailLink) {
//     return;
//   }
//   let email = window.localStorage.getItem('emailForSignIn');
//   if (!email) {
//     // User opened the link on a different device. To prevent session fixation
//     // attacks, ask the user to provide the associated email again.
//     email = window.prompt('Please provide your email for confirmation.');
//   }
//   // The client SDK will parse the code from the link for you.
//   const result = await FirebaseAuthentication.signInWithEmailLink({
//     email,
//     emailLink,
//   });
//   // Clear email from storage.
//   window.localStorage.removeItem('emailForSignIn');
//   return result.user;
// };

export const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle({ mode: 'redirect', skipNativeAuth: true });

  // 2. Sign in on the web layer using the id token
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  const auth = getAuth();
  const userCredential = await signInWithCredential(auth, credential);
  return userCredential.user;
};

// const signInWithPhoneNumber = async () => {
//   return new Promise(async resolve => {
//     // Attach `phoneCodeSent` listener to be notified as soon as the SMS is sent
//     await FirebaseAuthentication.addListener('phoneCodeSent', async event => {
//       // Ask the user for the SMS code
//       const verificationCode = window.prompt(
//         'Please enter the verification code that was sent to your mobile device.',
//       );
//       // Confirm the verification code
//       const result = await FirebaseAuthentication.confirmVerificationCode({
//         verificationId: event.verificationId,
//         verificationCode,
//       });
//       resolve(result.user);
//     });
//     // Attach `phoneVerificationCompleted` listener to be notified if phone verification could be finished automatically
//     await FirebaseAuthentication.addListener(
//       'phoneVerificationCompleted',
//       async event => {
//         resolve(event.result.user);
//       },
//     );
//     // Start sign in with phone number and send the SMS
//     await FirebaseAuthentication.signInWithPhoneNumber({
//       phoneNumber: '123456789',
//     });
//   });
// };

export const signOutFire = async () => {
  const userStore = useUserStore();
  userStore.logout();
  await FirebaseAuthentication.signOut();
  // 1. Sign out on the web layer
  const auth = getAuth();
  await signOut(auth);
};

export const updateEmail = async (newEmail: string) => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.updateEmail({
    newEmail,
  });
};

export const updatePassword = async (newPassword: string) => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.updatePassword({
    newPassword,
  });
};

// const useAppLanguage = async () => {
//   await FirebaseAuthentication.useAppLanguage();
// };

export const useEmulator = async () => {
  console.info('switching to emulator');
  await FirebaseAuthentication.useEmulator({
    host: 'http://localhost',
    port: 9099,
  });
  await connectAuthEmulator(getAuth(), 'http://localhost:9099');
};
