<template>
  <!-- Modal -->
  <div>
    <TransitionRoot as="template" :show="productStore.showBuyCoinsModal">
      <Dialog as="div" class="relative z-10" @close="productStore.toggleBuyCoinsModal(false)">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-2xl bg-black text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-sm px-4 min-h-80"
              >
                <!-- x button to close modal -->
                <div class="flex justify-end py-4">
                  <button @click="productStore.toggleBuyCoinsModal(false)">
                    <Icon name="ion:close-round" class="h-6 w-6 text-white" />
                  </button>
                </div>

                <!-- Content -->
                <div
                  v-if="userStore.uwitUser?.isGuest === true"
                  class="font-poppins bg-black w-full flex flex-col rounded-2xl pb-16 text-white"
                >
                  <p class="mt-4">Before purchasing powerups, please create an account.</p>
                  <div class="flex mt-10">
                    <NuxtLink
                      href="/auth/complete-profile"
                      class="btn-light-outlined"
                      @click="productStore.toggleBuyCoinsModal(false)"
                      >Create Account</NuxtLink
                    >
                  </div>
                </div>
                <div v-else class="font-poppins bg-black w-full flex flex-col rounded-2xl pb-16">
                  <div class="flex justify-center py-4 m-auto max-w-[250px]">
                    <DialogTitle as="div" class="font-bold text-white text-4xl text-center">
                      power up your play
                    </DialogTitle>
                  </div>

                  <CommonStripeCheckout
                    v-if="isNativePlatform === false"
                    :products="products"
                    @close-modal="handlePaymentModalClose"
                  />
                  <CommonNativeCheckout v-if="isNativePlatform === true" :products="products" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, TransitionChild, TransitionRoot, DialogTitle, DialogPanel } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import { Capacitor } from '@capacitor/core';

const productStore = useProductStore();
const userStore = useUserStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

// check if device is native (not running in web browser)
const isNativePlatform = ref(false);
isNativePlatform.value = Capacitor.isNativePlatform();

// Get the products from firestore (this will only run if stale since we fetched in InAppPurchases.vue)
const { data: products, isLoading: loadingProducts } = useQuery(['products'], () => productQuery(), {
  ...globalVueQueryOptions(),
  select: (firestoreProductsRaw) => {
    // Filter out unpublished products
    const dataFiltered = firestoreProductsRaw.filter((product) => product.published === enumPublished.YES);
    // Sort products by price
    const dataSorted = dataFiltered.sort(function (a, b) {
      return a.price - b.price;
    });
    // Replace product icon URLs with local image paths
    const dataWithLocalImages = dataSorted.map((product) => {
      return {
        ...product,
        icon: `/images/${product.icon}`,
      };
    });
    return dataWithLocalImages;
  },
});

const handlePaymentModalClose = () => {
  console.info('handlePaymentCanceled toggle');
  productStore.toggleBuyCoinsModal(false);
};
</script>
