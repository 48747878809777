<template>
  <div v-show="stripeClientSecret && !isLoading && !errorMessage && !successMessage" class="flex flex-col">
    <div id="payment-element" class="p-4"></div>
    <div class="flex flex-row gap-4 px-4">
      <button
        class="font-quicksand font-medium text-black bg-white w-full py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#e60055] active:text-white"
        type="button"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="font-quicksand font-medium text-black bg-white w-full py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#e60055] active:text-white"
        type="button"
        @click="completePayment"
      >
        Pay
      </button>
    </div>
  </div>

  <div v-if="isLoading" class="flex justify-center">
    <div class="flex justify-center items-center">
      <Icon name="ion:refresh-outline" class="animate-spin h-10 w-10 text-white" />
    </div>
  </div>

  <div v-if="errorMessage" class="flex flex-col text-center text-red-500">
    {{ errorMessage }}
    <div class="flex flex-row gap-4 px-4">
      <button
        class="font-quicksand font-medium text-black bg-white w-full mt-5 py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#e60055] active:text-white"
        type="button"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="font-quicksand font-medium text-black bg-white w-full mt-5 py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#e60055] active:text-white"
        type="button"
        @click="reset"
      >
        Try Again
      </button>
    </div>
  </div>

  <div v-if="successMessage" class="flex justify-center">
    <div class="flex flex-col justify-center items-center text-green-500">
      {{ successMessage }}
      <button
        class="font-quicksand font-medium text-black bg-white w-full mt-5 py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#e60055] active:text-white"
        type="button"
        @click="complete"
      >
        Awwww yeah!
      </button>
    </div>
  </div>

  <div v-show="!stripeClientSecret && !isLoading && !errorMessage && !successMessage" class="flex flex-wrap">
    <div v-for="(product, index) in products" :key="product.id" class="flex w-1/2 items-end justify-center">
      <div class="flex flex-col justify-end items-center py-2">
        <div>
          <img :src="product.icon" class="w-24 py-1" />
        </div>
        <div class="text-white py-1 text-center">
          <div class="font-bold" :class="products && index > 1 ? 'text-xl' : 'text-lg'">{{ product.coins }} coins</div>
          <div v-if="product.bonusPoints" class="font-quicksand text-sm font-medium">
            + {{ formatNumber(product.bonusPoints) }} point bonus!
          </div>
        </div>
        <div class="py-1">
          <button
            class="min-w-[90px] font-quicksand font-medium text-black bg-white py-2 rounded-3xl hover:bg-[#e60055] hover:text-white active:bg-[#7800CD] active:text-white"
            :disabled="isLoading"
            @click="startPayment(product)"
          >
            {{ formatCurrency(product.price) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { loadStripe, type StripeElement } from '@stripe/stripe-js';

const emit = defineEmits<{
  (event: 'closeModal'): () => void;
}>();

// props
interface Props {
  products: Product[] | undefined;
}
defineProps<Props>();

// runtime config
const config = useRuntimeConfig();

const stripePromise = loadStripe(config.public.stripeKey);
const stripeClientSecret = ref(null);
const elements = ref();
const isLoading = ref(false);
const selectedProduct = ref<Product | null>(null);
const errorMessage = ref<string | undefined>(undefined);
const successMessage = ref<string | undefined>(undefined);
let paymentElement: StripeElement | null = null;

const reset = () => {
  // stripeClientSecret.value = null;
  // selectedProduct.value = null;
  // elements.value = null;
  isLoading.value = false;
  errorMessage.value = undefined;
  successMessage.value = undefined;
};

const cancel = () => {
  stripeClientSecret.value = null;
  isLoading.value = false;
  selectedProduct.value = null;
  errorMessage.value = undefined;
  successMessage.value = undefined;
  elements.value = null;
  emit('closeModal');
};

const complete = () => {
  stripeClientSecret.value = null;
  isLoading.value = false;
  selectedProduct.value = null;
  errorMessage.value = undefined;
  elements.value = null;
  emit('closeModal');
};

const startPayment = async (product: Product) => {
  isLoading.value = true;
  selectedProduct.value = product;

  const jwtToken = await getIdToken();

  const response = await $fetch(`/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify({
      productId: selectedProduct.value?.id,
    }),
    ...globalFetchOptions(),
  });

  const { clientSecret } = response;
  stripeClientSecret.value = clientSecret;

  const stripe = await stripePromise;
  console.log('Stripe.js loaded');
  elements.value = stripe!.elements({ clientSecret, appearance: { theme: 'night', labels: 'floating' } });
  paymentElement = elements.value.create('payment');
  if (paymentElement) {
    paymentElement.mount('#payment-element');
  } else {
    throw new Error('Payment element not found');
  }
  setTimeout(() => {
    isLoading.value = false;
  }, 1000);
};

const completePayment = async () => {
  isLoading.value = true;
  const stripe = await stripePromise;

  const result = await stripe!.confirmPayment({
    elements: elements.value,
    redirect: 'if_required',
  });

  if (result.error) {
    // Show error to your customer.
    console.error(result.error.message);
    errorMessage.value = result.error.message;
    isLoading.value = false;
  } else {
    if (result.paymentIntent!.status === 'succeeded') {
      console.log('Payment succeeded!', result.paymentIntent);
      isLoading.value = false;
      successMessage.value = 'Success! Your coins are on their way!';
      // the payment is handled by stripe calling our webhook directly, so we don't need to do anything here
    }
    isLoading.value = false;
  }
};
</script>
