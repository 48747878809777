// Import necessary libraries and components
import { collection, getDocs } from 'firebase/firestore';
import { Product } from './Types';

export const productQuery = async (): Promise<Product[]> => {
  const productStore = useProductStore();
  const db = useFirestore();
  if (productStore.currentProducts.length > 0) {
    return productStore.currentProducts;
  }

  const ref = collection(db, 'products').withConverter(firebaseConverter<Product>());
  const querySnapshot = await getDocs(ref);
  const data = querySnapshot.docs.map((doc) => doc.data());

  productStore.setProducts(data);

  return data;
};
