import { arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash } from '@formkit/icons';
import '@formkit/themes/genesis';
import theme from './formkit.theme.js';
import { generateClasses } from '@formkit/themes';
import { createFloatingLabelsPlugin } from '@formkit/addons';
import '@formkit/addons/css/floatingLabels';

const config = {
  config: {
    // pass our theme object to generateClasses
    classes: generateClasses(theme),
  },
  plugins: [
    createFloatingLabelsPlugin({
      useAsDefault: true, // defaults to false
    }),
  ],
  icons: {
    arrowDown,
    arrowUp,
    close,
    checkboxDecorator: check,
    fileItem: fileDoc,
    fileRemove: close,
    noFiles: fileDoc,
    radioDecorator: circle,
    select: down,
    spinner,
    star,
    trash,
  },
};

export default config;
