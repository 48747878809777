<template>
  <div
    aria-live="assertive"
    class="fixed bottom-0 right-0 min-w-[400px] flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
  >
    <div v-for="toast in getToast" :key="toast.key" class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="showToast"
          class="pointer-events-auto flex w-full max-w-md rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
          :class="{
            'bg-yellow-500 border-yellow-600': toast.type == ToastEnum.warning,
            'bg-green-500 border-green-600': toast.type == ToastEnum.success,
            'bg-red-500 border-red-600': toast.type == ToastEnum.error,
          }"
        >
          <div class="w-0 flex-1 p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1">
                <p class="text-md font-medium text-white">{{ toast.title }}</p>
                <p class="mt-1 text-md text-gray-200">{{ toast.message }}</p>
              </div>
            </div>
          </div>
          <div class="flex border-l border-gray-200">
            <button
              type="button"
              @click="useErrorHandler.closeToast(toast.key)"
              class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-white hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup lang="ts">
const useErrorHandler = useErrorHandlerStore();

const getToast = computed<Array<ToastType>>(() => {
  return useErrorHandler.toastList;
});
const showToast = computed(() => {
  return useErrorHandler.toastList.length > 0;
});
</script>
