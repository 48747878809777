import payload_plugin_tsjLyLDySa from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase@10.10.0_vuefire@3.1.23/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_nsi4v6SBji from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fbbI9wzR9f from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2hI6ENsjGB from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+ionic@0.13.1/node_modules/@nuxtjs/ionic/dist/runtime/router.mjs";
import payload_client_eM29NCfdS7 from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Ny0KGI6fAJ from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_TInKzum75l from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/.nuxt/components.plugin.mjs";
import prefetch_client_B9GVXohef8 from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/.nuxt/formkitPlugin.mjs";
import plugin_client_mzNh6ObFdk from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase@10.10.0_vuefire@3.1.23/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/.nuxt/vuefire-plugin.mjs";
import composition_3T478t5BDY from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.13_idb-keyval@6.2.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_ljOaW8fK9B from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.13_idb-keyval@6.2.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import ionic_a4vhalR4YK from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+ionic@0.13.1/node_modules/@nuxtjs/ionic/dist/runtime/ionic.mjs";
import chunk_reload_client_rFzVZf4Zwr from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.0_vite@5.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_ZGaSyn0RZJ from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _0_sentry_YD2SvzFSDA from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/plugins/0.sentry.ts";
import _1_vue_query_client_H11EqkVrVK from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/plugins/1.vue-query.client.ts";
import _2_firebase_client_Wutm3pNbia from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/plugins/2.firebase.client.ts";
import _3_animoto_client_rwYDzkKsxF from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/plugins/3.animoto.client.ts";
import _4_vue_social_sharing_client_vfW0f4FuCq from "/Users/jflowerhome/devProjects/uwit/packages/uwit-daily-app/plugins/4.vue-social-sharing.client.ts";
export default [
  payload_plugin_tsjLyLDySa,
  revive_payload_client_nsi4v6SBji,
  unhead_fbbI9wzR9f,
  router_2hI6ENsjGB,
  payload_client_eM29NCfdS7,
  check_outdated_build_client_Ny0KGI6fAJ,
  plugin_vue3_TInKzum75l,
  components_plugin_KR1HBZs4kY,
  prefetch_client_B9GVXohef8,
  formkitPlugin_pZqjah0RUG,
  plugin_client_mzNh6ObFdk,
  vuefire_plugin_8cq4FnzXKb,
  composition_3T478t5BDY,
  i18n_ljOaW8fK9B,
  ionic_a4vhalR4YK,
  chunk_reload_client_rFzVZf4Zwr,
  plugin_ZGaSyn0RZJ,
  _0_sentry_YD2SvzFSDA,
  _1_vue_query_client_H11EqkVrVK,
  _2_firebase_client_Wutm3pNbia,
  _3_animoto_client_rwYDzkKsxF,
  _4_vue_social_sharing_client_vfW0f4FuCq
]